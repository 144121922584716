import React, { useState, useEffect } from "react";
import { Drawer, Form, Button, Radio, Select, notification } from "antd";
import Request from "../../../Requests";

import { PlusOutlined } from "@ant-design/icons";
import PartnerPackagesDrawer from "../../../pages/partner-packages/PartnerPackagesDrawer";

export default function ResellerTypeDrawer({
  open,
  onClose,
  providerInfo,
  getProviderInfo,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [partnerPackagesList, setPartnerPackagesList] = useState([]);
  const [addNewPackage, setAddNewPackage] = useState(false);

  useEffect(() => {
    getOptionsPartnerPackages();
  }, []);

  useEffect(() => {
    if (providerInfo?.is_partner && providerInfo?.is_seller) {
      form.setFields([
        {
          name: "reseller_type",
          value: "is_seller",
        },
      ]);
    } else if (providerInfo?.is_partner) {
      form.setFields([
        {
          name: "reseller_type",
          value: "is_partner",
        },
      ]);
    } else {
      form.setFields([
        {
          name: "reseller_type",
          value: "is_reseller",
        },
      ]);
    }
  }, [providerInfo]);

  const getOptionsPartnerPackages = (message) => {
    Request.partner_packages
      .get({ query: JSON.stringify({ page: 1, limit: 1000 }) })
      .then((data) => {
        if (data?.rows) {
          const findDisabledPack = data?.rows?.find(
            (item) => providerInfo?.partner_package_id == item.id
          );
          if (findDisabledPack) {
            form.setFields([
              {
                name: "partner_package_id",
                value: findDisabledPack?.id,
                errors: findDisabledPack.archived
                  ? ["This package archived!"]
                  : [],
              },
            ]);
          }
          const options = data?.rows?.map((item) => ({
            label: `${item.name} ( Activations ${item.device_count} ) $${item.price} `,
            value: item.id,
            disabled: item.archived,
          }));

          if (message == "set field") {
            form.setFields([
              {
                name: "partner_package_id",
                value: options[options?.length - 1]?.value,
              },
            ]);
          }

          setPartnerPackagesList(options);
        }
      })
      .catch((error) => {
        console.error("Error fetching partner packages:", error);
      });
  };
  const onFinish = (values) => {
    const resellerType = {
      id: Number(providerInfo?.id),
    };

    if (
      values.partner_package_id &&
      providerInfo.partner_package_id !== values.partner_package_id
    ) {
      resellerType.partner_package_id = Number(values.partner_package_id);
    }

    if (
      values.reseller_type == "is_partner" &&
      !providerInfo?.is_partner &&
      !providerInfo?.is_seller
    ) {
      resellerType.is_partner = true;
      resellerType.is_seller = false;
    }

    if (values.reseller_type == "is_seller" && !providerInfo?.is_seller) {
      resellerType.is_partner = true;
      resellerType.is_seller = true;
    }

    Request.provider_change_status(resellerType)
      .then(() => {
        setLoading(false);
        notification.success({ description: "Reseller type updated!" });
        getProviderInfo();
        onClose();
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };
  return (
    <>
      <Drawer title="Change Reseller types" open={open} onClose={onClose}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="reseller_type" label="Reseller types">
            <Radio.Group style={{ display: "flex" }}>
              <Radio value="is_reseller">Reseller</Radio>
              <Radio value="is_partner">Partner</Radio>
              <Radio value="is_seller">Seller</Radio>
            </Radio.Group>
          </Form.Item>

          <div className="h-86 res-info-partner_package_id">
            <Form.Item
              name="partner_package_id"
              label="Packages"
              rules={[
                {
                  required: true,
                  message: "Please select package",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                options={partnerPackagesList}
                showSearch
                placeholder="Search by package"
                optionFilterProp="label"
                style={{ maxWidth: "500px" }}
              />
            </Form.Item>
            <Button
              onClick={() => setAddNewPackage(true)}
              icon={<PlusOutlined />}
              type="primary"
              size="larg"
              className="mt-5 btn-res-info-partner_package_id"
            />
          </div>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              style={{ marginTop: "10px" }}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <PartnerPackagesDrawer
        visible={addNewPackage}
        onClose={() => setAddNewPackage(false)}
        isGetOptions={true}
        getOptionsPartnerPackages={getOptionsPartnerPackages}
      />
    </>
  );
}
