import axios from "axios";
import env from "react-dotenv";

let host = env.REACT_APP_API_HOST || "https://dev-api.xtream.cloud/"; // "http://192.168.8.172:41500/";
let host_dev_ads = "https://dev-ads.xtream.cloud/";

let url = {
  alerts: host + "admin/messages",
  app_brandings: host + "admin/app_brandings",
  billing_history_admin: host + "admin/provider/billing_amount",
  billing_history: host + "admin/billing_history",
  billing_amount: host + "admin/provider/billing_amount_sum",
  login: host + "admin/admins/login",
  provider: host + "admin/provider",
  news: host + "news/admin",
  notifications: host + "admin/notifications",
  platforms: host + "admin/platforms",
  resller_devices: host + "admin/provider_devices",
  devices_edit: host + "admin/provider_devices",
  partner_packages: host + "admin/partner_packages",
  provider_info: host + "admin/provider/info",
  provider_country_chart: host + "admin/provider/country_chart",
  provider_count: host + "admin/provider/count",
  payment_chart: host + "admin/provider/payment_chart",
  credit_statistics: host + "admin/provider/credit_statistics",
  card_total_statistics: host + "admin/provider/total_statistics",
  device_state_changes: host + `admin/provider_devices/state_changes`,
  all_devices: host + "admin/provider_devices/all_devices",
  player_error_logs: host + "admin/player_error_logs",
  subtitle: host + "admin/subtitle",
  partner_provider_os: host + "admin/partner_provider_os",
  sub_resellers: host + "admin/provider/sub_resellers",
  device_playlist_changes: host + "admin/playlist_changes",
  profile: host + "admin/admins/profile",
  admins: host + "admin/admins",
  credit_provider: host + "admin/provider/credit",
  device_deactivate: host + "admin/provider_devices/deactivate",
  provider_registration_chart: host + "admin/provider/registration_chart",
  provider_register: host + "admin/provider",
  provider_validate_email: host + "admin/provider/validate_email",
  provider_validate_brand_name: host + "admin/provider/validate_brand_name",
  provider_statistics: host + "admin/provider/register/by_admin", // "Permission denied"
  banner_ad: host_dev_ads + "admin",
  reset_device: host + "admin/provider_devices/cleaning",
  deactivate_provider_devices: host + "admin/provider_devices/deactivate/bulk",
  provider_change_status: host + "admin/provider/change_status",
};

let Request = {
  // A
  alerts: {
    get: (body) => {
      return request("get", url.alerts, body);
    },
    post: (body) => {
      return request("post", url.alerts, body);
    },
    put: (body) => {
      return request("put", url.alerts, body);
    },
    delete: (body) => {
      return request("delete", url.alerts, body);
    },
  },

  app_brandings: {
    get: (body) => {
      return request("get", url.app_brandings, body);
    },
    put: (body) => {
      return request("put", url.app_brandings, body);
    },
  },

  admins: {
    get: (body) => {
      return request("get", url.admins, body);
    },
    put: (body) => {
      return request("put", url.admins, body);
    },
    post: (body) => {
      return request("post", url.admins, body);
    },
  },
  // B
  banner_ad: {
    get: (body) => {
      return request("get", url.banner_ad, body);
    },
    edit: (body) => {
      return request("put", url.banner_ad, body);
    },
    delete: (body) => {
      return request("delete", url.banner_ad, body);
    },
    deactivate: (body) => {
      return request("put", `${url.banner_ad}/deactivate`, body);
    },
    pay: (body) => {
      return request("put", `${url.banner_ad}/pay`, body);
    },
  },
  billing_history_admin: {
    get: (query) => {
      return request("get", url.billing_history_admin, query);
    },
  },
  billing_history: {
    get: (body) => {
      return request("get", url.billing_history, body);
    },
    credit_transaction: (query) => {
      return request("get", url.billing_history, query);
    },
    total_sum: (query) => {
      return request("get", `${url.billing_history}/total_sum`, query);
    },
  },
  billing_amount: {
    get: (query) => {
      return request("get", url.billing_amount, query);
    },
  },
  // C
  credit_statistics: (body) => {
    return request("get", url.credit_statistics, body);
  },
  card_total_statistics: (body) => {
    return request("get", url.card_total_statistics, body);
  },
  // D
  dashboard: {
    provider_country_chart: (body) => {
      return request("get", url.provider_country_chart, body);
    },
    provider_count: (body) => {
      return request("get", url.provider_count, body);
    },
  },
  all_devices: (body) => {
    return request("get", url.all_devices, body);
  },
  device_state_changes: (body) => {
    return request("get", url.device_state_changes, body);
  },
  device_playlist_changes: (body) => {
    return request("get", url.device_playlist_changes, body);
  },
  device_deactivate: (body) => {
    return request("put", url.device_deactivate, body);
  },
  devices_edit: (body) => {
    return request("put", url.devices_edit, body);
  },
  deactivate_provider_devices: (body) => {
    return request("put", url.deactivate_provider_devices, body);
  },
  provider_registration_chart: (body) => {
    return request("get", url.provider_registration_chart, body);
  },

  // E
  // F
  // G
  // H
  // I
  // J
  // K
  // L
  login: {
    post: (body) => {
      return request("post", url.login, body);
    },
  },
  // M
  // N
  news: {
    get: (body) => {
      return request("get", url.news, body);
    },
    post: (body) => {
      return request("post", url.news, body);
    },
    put: (body) => {
      return request("put", url.news, body);
    },
    delete: (body) => {
      return request("delete", url.news, body);
    },
  },
  notifications: {
    get: (body) => {
      return request("get", url.notifications, body);
    },
    post: (body) => {
      return request("post", url.notifications, body);
    },
    put: (body) => {
      return request("put", url.notifications, body);
    },
    delete: (body) => {
      return request("delete", url.notifications, body);
    },
  },
  // O
  // P
  profile: (body) => {
    return request("get", url.profile, body);
  },
  credit_provider: (body) => {
    return request("put", url.credit_provider, body);
  },
  partner_provider_os: {
    get: (body) => {
      return request("get", url.partner_provider_os, body);
    },
    put: (body) => {
      return request("put", url.partner_provider_os, body);
    },
  },
  provider: {
    get: (body) => {
      return request("get", url.provider, body);
    },
    put: (body) => {
      return request("put", url.provider, body);
    },
    provider_info: (body) => {
      return request("get", url.provider_info, body);
    },
    post: (body) => {
      return request("post", url.provider_register, body);
    },
    validate_email: (body) => {
      return request("get", url.provider_validate_email, body);
    },
    validate_brand_name: (body) => {
      return request("get", url.provider_validate_brand_name, body);
    },
    block: (body) => {
      return request("put", `${url.provider}/block`, body);
    },
  },
  provider_change_status: (body) => {
    return request("put", url.provider_change_status, body);
  },

  provider_statistics: {
    get: (body) => {
      return request("get", url.provider_statistics, body);
    },
  },

  partner_packages: {
    get: (body) => {
      return request("get", url.partner_packages, body);
    },
    post: (body) => {
      return request("post", url.partner_packages, body);
    },
    put: (body) => {
      return request("put", url.partner_packages, body);
    },
  },
  payment_chart: (body) => {
    return request("get", url.payment_chart, body);
  },
  platforms: {
    get: (body) => {
      return request("get", url.platforms, body);
    },
  },
  player_error_logs: {
    get: (body) => {
      return request("get", url.player_error_logs, body);
    },
  },
  // Q
  // R
  resller_devices: {
    get: (body, id) => {
      return request("get", `${url.resller_devices}/devices?id=${id}`, body);
    },
    by_status: (body) => {
      return request("get", `${url.resller_devices}/by_status`, body);
    },
    by_platforms: (body) => {
      return request("get", `${url.resller_devices}/by_platforms`, body);
    },
    yearly_chart: (body) => {
      return request("get", `${url.resller_devices}/yearly_chart`, body);
    },
    deactivated: (body, id) => {
      return request(
        "get",
        `${url.resller_devices}/deactivated?id=${id}`,
        body
      );
    },
  },
  reset_device: (body) => {
    return request("put", url.reset_device, body);
  },
  // S
  subtitle: {
    get: (body) => {
      return request("get", url.subtitle, body);
    },
    put: (body) => {
      return request("put", url.subtitle, body);
    },
  },
  sub_resellers: (body) => {
    return request("get", url.sub_resellers, body);
  },
  // T
  // U
  // V
  // W
  // X
  // Y
  // Z
};

const request = async (method, url, data) => {
  let options = {
    url,
    method,
    timeout: 80000,
    headers: {
      "content-type": "application/json",
    },
  };

  if (localStorage.getItem("token")) {
    options.headers["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  }

  if (method == "get") {
    options.params = data;
  } else {
    options.data = data;
  }
  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    const url = window.location.href;
    let parts = url.split("#")[1];
    let queryPart = parts.split("?")[0];

    if (error.response.data == "Forbidden") {
      if (queryPart == "/dashboard/news") {
        throw new Error(error.response.data);
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
    } else {
      throw new Error(error.response.data);
    }
  }
};

export default Request;
