import { useEffect, useState } from "react";
import {
  Form,
  Button,
  notification,
  Input,
  Tooltip,
  Select,
  Radio,
  Modal,
  InputNumber,
  Space,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  TransactionOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  RightOutlined,
} from "@ant-design/icons";

import { createFieldsValue } from "../../../Utils";

import Request from "../../../Requests";

import { useCountries } from "../../../hooks/useSelctOptions";

import { returnGeneratePassword, copyText } from "../../../configs/config";

import { ConfirmComponent } from "../../../components";

import CreditDrawer from "./CreditDrawer";

import ResellerInfoDrawer from "../ResellerInfoDrawer";
import ResellerTypeDrawer from "./ResellerTypeDrawer";

export default function ResellerInfo({
  theProviderId,
  providerInfo,
  getProviderInfo,
  form,
  setDeactivatedAllDevices,
  setGetResellers,
}) {
  const countriesOptions = useCountries();

  const [blockReseller, setBlockReseller] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [onParentId, setOnParentId] = useState(false);

  const [credit, setCredit] = useState({
    show: false,
    type: "",
  });

  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState(null);

  const [createdByAdmin, setCreatedByAdmin] = useState(null);

  const [resellerType, setResellerType] = useState("");
  const [isResellerTypeDrawer, setIsResellerTypeDrawer] = useState(false);

  useEffect(() => {
    getProfile();
    getAdminsData();
  }, []);

  useEffect(() => {
    if (providerInfo && theProviderId) {
      setBlockReseller(providerInfo?.blocked);
      form.setFieldsValue(createFieldsValue(providerInfo, fields));
    } else {
      form.resetFields();
    }

    if (!providerInfo.is_partner && !providerInfo.is_seller) {
      setResellerType("Reseller");
    }
    if (providerInfo.is_partner && !providerInfo.is_seller) {
      setResellerType("Partner");
    }
    if (providerInfo.is_partner && providerInfo.is_seller) {
      setResellerType("Seller");
    }
  }, [providerInfo, theProviderId]);

  const getAdminsData = () => {
    Request.admins
      .get()
      .then((data) => {
        setLoading(false);
        const admin = data.find((admin) => admin.id === providerInfo?.admin_id);
        if (admin) {
          const fullName = `${admin.name || ""} ${admin.surname || ""}`.trim();
          setCreatedByAdmin(fullName || admin.email);
        } else {
          setCreatedByAdmin("The reseller completed their own registration");
        }
      })
      .catch(({ message }) => {});
  };

  const generatePassword = () => {
    form.setFields([
      {
        name: "password",
        value: returnGeneratePassword(),
      },
    ]);
  };

  const validateBrandName = (rule, value, callback) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (
      (typeof value === "string" || typeof value === "number") &&
      regex.test(value)
    ) {
      callback();
      setDisabled(false);
    } else {
      callback("Brand name must include only letters or numbers");
      setDisabled(true);
    }
  };

  const getProfile = () => {
    Request.profile()
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {});
  };

  const onFinish = (values) => {
    setLoading(true);
    if (!providerInfo) {
      return;
    }

    if (values.country == providerInfo?.country) {
      delete values.country;
    }

    if (values.brand_name == providerInfo?.brand_name) {
      delete values.brand_name;
    }

    if (values.app_name == providerInfo?.app_name) {
      delete values.app_name;
    }

    if (values.email == providerInfo?.email) {
      delete values.email;
    }

    if (values.phone_number == providerInfo?.phone_number) {
      delete values.phone_number;
    }

    const formattedValues = {
      ...values,
      id: Number(theProviderId),
    };

    // delete formattedValues.reseller_type;
    delete formattedValues.partner_key;
    // delete formattedValues.partner_package_id;

    const filteredObj = Object.keys(formattedValues).reduce((acc, key) => {
      const value = formattedValues[key];
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    Modal.confirm({
      title: "Do you want to change these info?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        Request.provider
          .put(filteredObj)
          .then(() => {
            setLoading(false);
            notification.success({ description: "Reseller info updated!" });
            getProviderInfo();
            setGetResellers(true);
          })
          .catch(({ message }) => {
            setLoading(false);
            notification.error({ message });
          });
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  // const onFieldsChange = (changedFields, allFields) => {
  // if (changedFields[0].name[0] === "is_partner") {
  //   if (changedFields[0].value === false) {
  //     form.setFieldsValue({ partner_package_id: null });
  //     form.setFieldsValue({ is_seller: false });
  //   }
  // }
  // };

  const onBlock = () => {
    if (theProviderId) {
      Request.provider
        .block({ id: theProviderId, blocked: !blockReseller })
        .then((res) => {
          notification.success({ description: res });
          setBlockReseller(!blockReseller);
        })
        .catch((err) => notification.error({ description: err?.message }));
    }
  };

  const onResetDevices = () => {
    if (theProviderId) {
      Request.deactivate_provider_devices({
        provider_id: Number(theProviderId),
      })
        .then((res) => {
          setDeactivatedAllDevices(true);
          notification.success({ description: res });
        })
        .catch((err) => notification.error({ description: err?.message }));
    }
  };

  const fields = {
    id: {
      type: "INTEGER",
      rules: [{ required: false, message: "" }],
    },

    brand_name: {
      type: "STRING",
      rules: [
        { required: false, message: "" },
        { validator: validateBrandName },
      ],
    },
    app_name: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    partner_key: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    email: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    password: {
      type: "STRING",
      rules: [
        { required: false, message: "" },
        {
          min: 8,
          message: "Password must be minimum 8 characters.",
        },
      ],
    },

    is_partner: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    is_seller: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },

    partner_package_id: {
      type: "NUMBER",
      rules: [{ required: false, message: "" }],
    },
    phone_number: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    country: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
  };

  return (
    <>
      <div>
        <p>Created by</p>
        <p>{createdByAdmin}</p>
        {providerInfo?.parent_id && (
          <div style={{ marginBottom: "10px" }}>
            Parent ID:{" "}
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => setOnParentId(true)}
            >
              {providerInfo?.parent_id}
            </Button>
          </div>
        )}
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFieldsChange={onFieldsChange}
        autoComplete="off"
        className="reseller-info-form"
      >
        <Form.Item
          label="Brand name"
          name="brand_name"
          rules={fields.brand_name.rules}
        >
          <Input placeholder="brand name" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={fields.email.rules}>
          <Input placeholder="email" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item
          label="App name"
          name="app_name"
          rules={fields.app_name.rules}
        >
          <Input placeholder="App name" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone_number"
          rules={fields.phone_number.rules}
        >
          <Input placeholder="Phone number" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const { password } = form.getFieldsValue();
            return (
              <Form.Item
                label="Password"
                name="password"
                rules={fields.password.rules}
              >
                <Input.Password
                  allowClear
                  placeholder="Password"
                  style={{ maxWidth: 500 }}
                  visibilityToggle={{ visible: true }}
                  addonAfter={
                    <Tooltip title="Generate password">
                      <TransactionOutlined
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "18px",
                          color: "#258ffb",
                          padding: 0,
                        }}
                        onClick={generatePassword}
                      />
                    </Tooltip>
                  }
                  addonBefore={
                    password && (
                      <CopyOutlined
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "18px",
                          color: "#258ffb",
                          padding: 0,
                        }}
                        onClick={() => copyText(password)}
                      />
                    )
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="Partner key"
          name="partner_key"
          rules={fields.partner_key.rules}
        >
          <Input
            placeholder="partner_key"
            style={{ maxWidth: 500 }}
            disabled
            addonBefore={
              <CopyOutlined
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  color: "#258ffb",
                  padding: 0,
                }}
                onClick={() => copyText(providerInfo?.partner_key)}
              />
            }
          />
        </Form.Item>
        {(providerInfo?.parent_id && !providerInfo?.is_partner) ||
        !profile?.permission?.edit_reseller ? (
          <></>
        ) : (
          <>
            <Form.Item label="Reseller types">
              {
                <Button
                  type="primary"
                  ghost
                  onClick={() => setIsResellerTypeDrawer(true)}
                >
                  {resellerType} <RightOutlined />
                </Button>
              }
            </Form.Item>
            {!providerInfo?.is_partner &&
              !providerInfo?.is_seller &&
              profile?.permission?.manage_credits && (
                <Form.Item label="Credit">
                  {" "}
                  {/* className="h-86" */}
                  <InputNumber
                    placeholder="Add credit"
                    controls={false}
                    value={providerInfo?.credit}
                    disabled
                    addonAfter={
                      <Tooltip title="Add credit">
                        <PlusOutlined
                          onClick={() => {
                            setCredit({
                              show: true,
                              type: "add",
                            });
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "18px",
                            color: "#258ffb",
                            padding: 0,
                          }}
                        />
                      </Tooltip>
                    }
                    addonBefore={
                      <Tooltip title="Take credit">
                        <MinusOutlined
                          onClick={() => {
                            setCredit({
                              show: true,
                              type: "take",
                            });
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "18px",
                            color: "red",
                            padding: 0,
                          }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              )}
          </>
          // <>
          //   <Form.Item
          //     name="reseller_type"
          //     label="Reseller types"
          //     className="h-86"
          //   >
          //     <Radio.Group style={{ display: "flex" }}>
          //       <Radio value="is_reseller">Reseller</Radio>
          //       <Radio value="is_partner">Partner</Radio>
          //       <Radio value="is_seller">Seller</Radio>
          //     </Radio.Group>
          //   </Form.Item>

          //   <Form.Item shouldUpdate noStyle>
          //     {() => {
          //       const { reseller_type } = form.getFieldsValue();
          //       return reseller_type == "is_reseller" &&
          //         profile?.permission?.manage_credits ? (
          //         <Form.Item label="Credit" className="h-86">
          //           <InputNumber
          //             placeholder="Add credit"
          //             controls={false}
          //             value={providerInfo?.credit}
          //             disabled
          //             addonAfter={
          //               <Tooltip title="Add credit">
          //                 <PlusOutlined
          //                   onClick={() => {
          //                     setCredit({
          //                       show: true,
          //                       type: "add",
          //                     });
          //                   }}
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "center",
          //                     fontSize: "18px",
          //                     color: "#258ffb",
          //                     padding: 0,
          //                   }}
          //                 />
          //               </Tooltip>
          //             }
          //             addonBefore={
          //               <Tooltip title="Take credit">
          //                 <MinusOutlined
          //                   onClick={() => {
          //                     setCredit({
          //                       show: true,
          //                       type: "take",
          //                     });
          //                   }}
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "center",
          //                     fontSize: "18px",
          //                     color: "red",
          //                     padding: 0,
          //                   }}
          //                 />
          //               </Tooltip>
          //             }
          //           />
          //         </Form.Item>
          //       ) : (
          //         <div className="h-86 res-info-partner_package_id">
          //           <Form.Item
          //             name="partner_package_id"
          //             label="Packages"
          //             rules={[
          //               {
          //                 required: reseller_type !== "is_reseller",
          //                 message: "Please select package",
          //               },
          //             ]}
          //             style={{ width: "100%" }}
          //           >
          //             <Select
          //               options={partnerPackagesList}
          //               showSearch
          //               placeholder="Search by package"
          //               optionFilterProp="label"
          //               style={{ maxWidth: "500px" }}
          //             />
          //           </Form.Item>
          //           <Button
          //             onClick={() => setAddNewPackage(true)}
          //             icon={<PlusOutlined />}
          //             type="primary"
          //             size="larg"
          //             className="mt-10 btn-res-info-partner_package_id"
          //           />
          //         </div>
          //       );
          //     }}
          //   </Form.Item>
          // </>
        )}
        <Form.Item
          label="Country"
          name="country"
          rules={fields.country.rules}
          // className={HIDE_RESELLER_TAPYE ? "" : "mt-20"}
        >
          <Select
            showSearch
            allowClear
            placeholder="Search by country"
            optionFilterProp="label"
            options={countriesOptions}
            style={{ maxWidth: "500px" }}
          />
        </Form.Item>
        {resellerType == "Reseller" && <Form.Item></Form.Item>}
        <Form.Item style={{ marginTop: "20px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            {profile?.permission_level && (
              <Space direction="horizontal">
                <ConfirmComponent
                  confirm={onResetDevices}
                  title={`Are you sure to deactivated devices this reseller ?`}
                  value="Deactivated Devices"
                  type="danger"
                />
                <ConfirmComponent
                  confirm={onBlock}
                  title={`Are you sure to ${
                    blockReseller ? "Unblock" : "Block"
                  } this reseller ?`}
                  value={blockReseller ? "Unblock" : "Block"}
                  type={blockReseller ? "primary" : "danger"}
                />
              </Space>
            )}
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              disabled={disabled}
            >
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      {profile?.permission?.manage_credits && (
        <CreditDrawer
          open={credit}
          onClose={() =>
            setCredit({
              show: false,
              type: "",
            })
          }
          providerId={theProviderId}
          getProviderInfo={getProviderInfo}
          currentCredit={providerInfo.credit}
        />
      )}

      {isResellerTypeDrawer && (
        <ResellerTypeDrawer
          open={isResellerTypeDrawer}
          onClose={() => setIsResellerTypeDrawer(false)}
          providerInfo={providerInfo}
          getProviderInfo={getProviderInfo}
        />
      )}

      {onParentId && providerInfo?.parent_id && (
        <ResellerInfoDrawer
          resellerId={providerInfo?.parent_id}
          open={onParentId}
          onClose={() => setOnParentId(false)}
        />
      )}
    </>
  );
}
