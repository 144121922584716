import { Typography } from "antd";

export default function TableText({
  text = "",
  style = {
    maxWidth: "170px",
  },
  onClick,
}) {
  return (
    <Typography.Text
      style={style}
      ellipsis={{ tooltip: text }}
      onClick={onClick}
    >
      {text}
    </Typography.Text>
  );
}
