import { useState, useEffect, useRef } from "react";

import { RightOutlined } from "@ant-design/icons";

import { Table, Tooltip, Button, Space, notification } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import {
  TableText,
  TableResetButton,
  TableSettingsButton,
  TableBoolean,
  TitlePage,
  TableDateHHMMSS,
  RangePickerFilterDropdown,
  TableCSV,
  CopyText,
  ResellerInfoDrawer,
  DeviceInfoDrawer,
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";

import useHistorySettings from "../../hooks/useHistorySettings";
import { getColumnSearchProps } from "../../configs/config";

export default function AllDevices() {
  const fieldsType = {
    id: "INTEGER",
    provider_id: "INTEGER",
    server_id: "INTEGER",
    app_name: "STRING",
    device_os: "STRING",
    device_key: "STRING",
    device_uuid: "STRING",
    ip_address: "STRING",
    playlist: "STRING",
    country: "STRING",
    activated_by: "ENUM",
    is_active: "BOOLEAN",
    is_trial: "BOOLEAN",
    auto_renew: "BOOLEAN",
    is_online: "BOOLEAN",
    free_trial_expired: "DATE",
    expired_date: "DATE",
    createdAt: "DATE",
    updatedAt: "DATE",
    app_version: "STRING",
    model: "STRING",
    browser_version: "STRING",
    online_date: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };
  const searchInputRef = useRef(null);

  const [data, setData] = useState([]);

  const [cardTotal, setCardTotal] = useState(null);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);
  const [dataForExport, setDataForExport] = useState([]);

  const [total, setTotal] = useState(0);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });

  const [openDeviceInfo, setOpenDeviceInfo] = useState({
    open: false,
    deviceKey: null,
  });

  useEffect(() => {
    getCardTotalStatistics();
  }, []);

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => record.index,
    },
    {
      title: "Device key",
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Playlist",
      dataIndex: "playlist",
      key: "playlist",
      align: "center",
      width: 170,
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => {
        const playlistStatus = record?.playlist_status?.toLowerCase();
        if (!record?.playlist) {
          return "N/A";
        } else if (playlistStatus === "wrong" || playlistStatus === "expired") {
          return (
            <>
              <Space>
                <Tooltip title={checkPlaylistForError(playlistStatus)}>
                  <Button
                    icon={<InfoCircleOutlined style={{ color: "#cf1222" }} />}
                    type="link"
                  />
                </Tooltip>

                <CopyText
                  text={
                    <TableText
                      text={record?.playlist}
                      style={{ color: "#cf1222" }}
                    />
                  }
                  style={{ color: "#cf1222" }}
                />
              </Space>
            </>
          );
        } else {
          return (
            <Space>
              <CopyText text={<TableText text={record?.playlist} />} />
            </Space>
          );
        }
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (text, record, index) => text,
      align: "center",
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Server ID",
      dataIndex: "server_id",
      key: "server_id",
      align: "center",
      render: (text, record, index) => (text ? text : "N/A"),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Activated by",
      dataIndex: "activated_by",
      key: "activated_by",
      align: "center",
      filters: [
        { text: "Device", value: "device" },
        { text: "Provider", value: "provider" },
      ],
      render: (text, record, index) => (
        <TableText
          text={(
            text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
          )?.replaceAll("_", " ")}
        />
      ),
    },
    {
      title: "Provider ID",
      dataIndex: "provider_id",
      key: "provider_id",
      render: (text, record, index) =>
        record.provider_id ? (
          <Button
            type="ghost"
            onClick={() =>
              setOpenResellerInfo({
                open: true,
                resellerId: record?.provider_id,
              })
            }
          >
            {text}
          </Button>
        ) : (
          "N/A"
        ),
      align: "center",
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "App name",
      dataIndex: "app_name",
      key: "app_name",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Device Os",
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Device uuid",
      dataIndex: "device_uuid",
      key: "device_uuid",
      align: "center",
      width: 150,
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Is active",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },
    {
      title: "Is trial",
      dataIndex: "is_trial",
      key: "is_trial",
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },

    {
      title: "Auto renew",
      dataIndex: "auto_renew",
      key: "auto_renew",
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },

    {
      title: "Is online",
      dataIndex: "is_online",
      key: "is_online",
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },
    {
      title: "IP address",
      dataIndex: "ip_address",
      key: "ip_address",
      align: "center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Free trial expired",
      dataIndex: "free_trial_expired",
      key: "free_trial_expired",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "Online date",
      dataIndex: "online_date",
      key: "online_date",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
      key: "expired_date",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "App version",
      dataIndex: "app_version",
      key: "app_version",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => {
        return record?.app_version ? (
          <TableText text={record.app_version} />
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => {
        return record?.model ? <TableText text={record.model} /> : "N/A";
      },
    },
    {
      title: "Browser version",
      dataIndex: "browser_version",
      key: "browser_version",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => {
        return record?.browser_version ? (
          <TableText text={record.browser_version} />
        ) : (
          "N/A"
        );
      },
    },

    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div
          onClick={() =>
            setOpenDeviceInfo({ open: true, deviceKey: record?.device_key })
          }
          className="on-row"
        >
          <RightOutlined />
        </div>
      ),
    },
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "platforms");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    setLoading(true);

    Request.all_devices(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }

        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total || data.count);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const resetTable = () => {
    setTableState(defaultTableState);
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState]);

  const onExport = (selected) => {
    if (selected == "allData") {
      Request.all_devices({ query: JSON.stringify({ pagination: 0 }) })
        .then((data) => {
          setDataForExport(data);
        })
        .catch(({ message }) => {
          notification.error({ message });
        });
    }
    if (selected == "data") {
      setDataForExport(data);
    }
  };

  const getCardTotalStatistics = () => {
    Request.card_total_statistics()
      .then((data) => {
        setCardTotal(data);
      })
      .catch(({ message }) => {});
  };
  const checkPlaylistForError = (playlistStatus) => {
    switch (playlistStatus) {
      case "wrong":
        return "Please check the playlist as it is incorrectly filled in.";
      case "expired":
        return "Please check this playlist as its expiration date has expired.";
      default:
    }
  };

  return (
    <>
      <TitlePage title="Devices" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
          marginBottom: 15,
        }}
      >
        <div className="tb-header">
          <div style={{ fontSize: "23px" }}>
            <span style={{ fontSize: "15px", marginRight: "10px" }}>
              Total:
            </span>
            {""}
            <span>{cardTotal?.total_devices || 0}</span>
          </div>
        </div>
        <div>
          <TableResetButton onClick={resetTable} />
          <TableSettingsButton
            columns={columns}
            onChangeColumns={onChangeColumns}
          />
          <TableCSV dataForExport={dataForExport} onExport={onExport} />
        </div>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />
      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
      <DeviceInfoDrawer
        deviceKey={openDeviceInfo?.deviceKey}
        open={openDeviceInfo?.open}
        onClose={() => setOpenDeviceInfo({ open: false, deviceKey: null })}
      />
    </>
  );
}
